var clearFieldValue = null;

$(document).on("focus", ".clear-field", function (e) {
    if ($(this).val() == 0) {
        clearFieldValue = $(this).val();
        // console.log($(this).attr("id") + " setting '' for value: " + $(this).val());
        $(this).val("");
    }
});

$(document).on("blur", ".clear-field", function (e) {
    if ($(this).val() == "") {
        $(this).val(clearFieldValue);
    }
});

$(document).on("ready page:load turbolinks:load", function () {
    // When the button with the id 'submit_service_record' is clicked
    $('#submit_service_record').click(function () {
        // Change the value of the hidden input field named 'button' to 'submit'
        $('input[name="button"]').val('submit');
    });

    // When the button with the id 'submit_finish_service_record' is clicked
    $('#submit_finish_service_record').click(function () {
        // Change the value of the hidden input field named 'button' to 'submit_finish'
        $('input[name="button"]').val('submit_finish');
    });

    // When the button with the id 'submit_file_upload' is clicked
    $('#submit_file_upload').click(function () {
        // Change the value of the hidden input field named 'button' to 'import'
        $('input[name="button"]').val('import');
    });

    // When the button with the id 'submit_file_upload_to_stock' is clicked
    $('#submit_file_upload_to_stock').click(function () {
        // Change the value of the hidden input field named 'button' to 'import_to_stock'
        $('input[name="button"]').val('import_to_stock');
    });


    $("#service-record-items").on(
        "cocoon:after-insert",
        function (e, task_to_be_added) {
            var nestedFields = $(".nested-fields");

            // this should set last item markup percentage - the one which has been added by cocoon
            var itemMarkupWrapper = nestedFields.last().find('.item-markup-percentage-wrapper');
            if ($("#car_service_record_use_custom_markup_percentage").is(":checked")) {
                // when #car_service_record_use_custom_markup_percentage is checked use item markup percentage
                // and show item markup percentage fields for new service record item
                itemMarkupWrapper.removeClass("d-none");
                // also add markup percentage to item, the one from global markup percentage field
                var markupPercent = $(".markup-percentage").val();
                nestedFields.last().find(".item-markup-percentage").val(markupPercent);
            } else {
                var markupPerc = $('.markup-percentage').val();
                nestedFields.last().find(".item-markup-percentage").val(markupPerc);
                // otherwise hide item markup percentage fields
                itemMarkupWrapper.addClass("d-none");
            }

            var itemDesc = nestedFields.last().find(".item-description");
            itemDesc.focus();

            var deleteLink = nestedFields.last().find(".remove_fields");
            deleteLink.attr("tabindex", "0");

            checkServiceRecordItems();
        },
    );

    // recalculating totals after service record item is removed
    $("#service-record-items").on("cocoon:after-remove", function () {
        $(".nested-fields:hidden").each(function () {
            var hiddenSumPrice = $(this).find(".hidden-item-price-total");
            hiddenSumPrice.text(0.0);
        });
        recordPriceSum();
        checkServiceRecordItems();
    });
});

// calculating prices from record items and also from other prices
// it should exclude deleted items
function recordPriceSum() {
    // console.log("========================");
    // console.log("executing recordPriceSum()");
    let sum = 0.0;
    $(".sum-fields").each(function () {
        if ($(this).is("span")) {
            var price = $(this).text();
        } else {
            var price = $(this).val();
        }

        // console.log("price: " + price);
        if (price != "") {
            sum += parseFloat(price);
        }
    });
    // console.log("total_price: " + sum);
    $("#car_service_record_total_price").val(sum);
}

function checkServiceRecordItems() {
    if ($('.nested-fields').length > 0) {
        // If there are service record items on the page, set the input field to readonly
        $('#car_service_record_total_price').prop('readonly', true);
    } else {
        // If there are no service record items on the page, remove the readonly attribute
        $('#car_service_record_total_price').prop('readonly', false);
    }
}

// Trigger element is used to determine what field triggered the calculation
var calculateServiceItem = function (nestedFields, triggerElement = null) {
    // login with timestamp
    console.log("executing calculateServiceItem(nestedFields): " + new Date().getTime());
    var itemTotalPriceEl = nestedFields.find(".item-price-total");
    var hiddenItemTotalPriceEl = nestedFields.find(".hidden-item-price-total");
    var qty = nestedFields.find(".item-quantity").val();
    var purchUnitPrice = nestedFields.find(".purchase-item-unit-price").val();
    var unitPrice = nestedFields.find(".item-unit-price").val();
    var vatPercent = nestedFields.find(".item-vat-percentage").val();
    var itemMarkupPercent = nestedFields.find(".item-markup-percentage").val();
    var markupPercent = $(".markup-percentage").val();

    // if quantity and price is set, calculate total price
    if (qty !== "" && (purchUnitPrice !== "" || unitPrice !== "")) {
        console.log("required values are set, calculating total price...")
        console.log("qty: " + qty + ", purchUnitPrice: " + purchUnitPrice + ", unitPrice: " + unitPrice);
        var perc = $("#car_service_record_use_custom_markup_percentage").is(":checked")
            ? new Big(itemMarkupPercent || 0)
            : new Big(markupPercent || 0);

        // if purchase unit price is set, calculate unit price with markup
        if (purchUnitPrice !== "") {
            console.log("purchUnitPrice is set, calculating unit price with markup...")
            console.log("perc: " + perc);

            // if custom markup percentage is used and triggerElement is item-unit-price
            // calculate item markup percentage from item unit price
            // and set item total price
            if ($("#car_service_record_use_custom_markup_percentage").is(":checked") && triggerElement && triggerElement.hasClass("item-unit-price")) {
                console.log("triggerElement is item-unit-price, calculating item markup percentage...");
                // Use big.js for calculations to avoid floating-point issues
                var calcUnitPrice = new Big(unitPrice);
                let calcItemMarkupPercent = calcUnitPrice
                    .div(purchUnitPrice)
                    .minus(1)
                    .times(100);
                nestedFields.find(".item-markup-percentage").val(calcItemMarkupPercent.toFixed(2));
            } else {
                // else calculate unit price with markup from purchase unit price
                var calcUnitPrice = new Big(purchUnitPrice)
                    .times(perc.div(100).plus(1));
                nestedFields.find(".item-unit-price").val(calcUnitPrice.toFixed(2));
            }

            // Calculate purchase unit price without VAT
            var purchUnitPriceWithoutVat = new Big(purchUnitPrice).div(new Big(1).plus(new Big(vatPercent).div(100)));
            nestedFields.find(".purchase-item-unit-price-without-vat").val(purchUnitPriceWithoutVat.toFixed(2));

            // Calculate unit price without VAT
            var unitPriceWithoutVat = new Big(calcUnitPrice).div(new Big(1).plus(new Big(vatPercent).div(100)));
            nestedFields.find(".item-unit-price-without-vat").val(unitPriceWithoutVat.toFixed(2));

            var itemTotalPrice = new Big(qty).times(calcUnitPrice);
        } else {
            // else calculate unit price with markup from unit price

            // Calculate unit price without VAT
            var unitPriceWithoutVat = new Big(unitPrice).div(new Big(1).plus(new Big(vatPercent).div(100)));
            nestedFields.find(".item-unit-price-without-vat").val(unitPriceWithoutVat.toFixed(2));

            var itemTotalPrice = new Big(qty).times(new Big(unitPrice));

            nestedFields.find(".purchase-item-unit-price-without-vat").val("");
        }

        // Update the total price display
        itemTotalPriceEl.html(
            Number(itemTotalPrice.toFixed(2)).toLocaleString(wLocale, {
                style: "currency",
                currencyDisplay: "symbol",
                currency: wCurrency,
            })
        );
        hiddenItemTotalPriceEl.html(itemTotalPrice.toFixed(2));
    } else {
        // If quantity or price is not set, reset to 0
        itemTotalPriceEl.html(
            "0.00".toLocaleString(wLocale, {
                style: "currency",
                currencyDisplay: "symbol",
                currency: wCurrency,
            }),
        );
        hiddenItemTotalPriceEl.html("0.00");

        nestedFields.find(".item-unit-price-without-vat").val("");
    }
};

// calculating total price for single record item and also making global price sum in the end
$(document).on(
    "keyup change",
    ".item-quantity, .item-unit-price, .purchase-item-unit-price, .item-vat-percentage, .item-markup-percentage",
    function (e) {
        var nestedFields = $(this).closest(".nested-fields");
        // accepting nestedFields and triggerElement as parameters
        // triggerElement is used to determine what field triggered the calculation
        calculateServiceItem(nestedFields, $(this));

        recordPriceSum();
    },
);

var calculateAllItemsAndTotal = function () {
    // Iterácia iba cez položky, ktoré neboli označené na odstránenie
    $(".nested-fields").not(":has(input[type='hidden'][name$='[_destroy]'][value='1'])").each(function () {
        var nestedFields = $(this);
        calculateServiceItem(nestedFields);
    });

    recordPriceSum();
};


window.recordPriceSum = recordPriceSum;
window.calculateAllItemsAndTotal = calculateAllItemsAndTotal;
window.calculateServiceItem = calculateServiceItem;

// calculating total price for single record item and also making global price sum in the end
// when global markup percentage is changed or when custom markup percentage checkbox is checked/unchecked
$(document).on("keyup change", ".markup-percentage", function (e) {
    calculateAllItemsAndTotal();
});

// when custom markup percentage checkbox is checked/unchecked
$(document).on(
    "change",
    "#car_service_record_use_custom_markup_percentage",
    function (e) {
        if ($("#car_service_record_use_custom_markup_percentage").is(":checked")) {
            // when checked fields are visible and can be edited
            $(".item-markup-percentage-wrapper").removeClass("d-none");
        } else {
            // when unchecked fields are hidden and cannot be edited
            $(".item-markup-percentage-wrapper").addClass("d-none");
        }
        // and recalculate all items
        calculateAllItemsAndTotal();
    },
);

// calculating price total
$(document).on("keyup change", ".sum-fields", function (e) {
    recordPriceSum();
});


$(document).ready(function() {
    function initAutocomplete($element) {
        $element.autocomplete({
            minLength: 2,  // začne vyhľadávať po napísaní aspoň 2 znakov
            source: function(request, response) {
                // používa rovnaké URL ako predtým TomSelect
                const url = $element.data('searchUrl');

                $.ajax({
                    url: url,
                    data: { q: request.term },
                    type: 'GET',
                    success: function(data) {
                        response(data.map(function(item) {
                            return {
                                label: item.description, // zobrazený text
                                value: item.description, // hodnota ktorá sa vloží do inputu
                                item: item // celý objekt pre ďalšie použitie
                            };
                        }));
                    }
                });
            },
            select: function(event, ui) {
                const $formGroup = $(this).closest('.nested-fields');
                if (ui.item && ui.item.item) {
                    // Ak používateľ vybral položku zo zoznamu, vyplníme dodatočné polia
                    $formGroup.find('.item-unit').val(ui.item.item.unit || '');
                    $formGroup.find('.item-unit-price').val(ui.item.item.unit_price || '');
                    $formGroup.find('.purchase-item-unit-price').val(ui.item.item.purchase_unit_price || '');
                    $formGroup.find('.item-vat-percentage').val(ui.item.item.vat_percents || '');
                }
            }
        }).data("ui-autocomplete")._renderItem = function(ul, item) {
            // Vlastné renderovanie položiek v dropdown menu
            return $("<li>")
                .append($("<div>")
                    .append($("<span>").text(item.label))
                    .append(item.item.unit ? $("<small>", {
                        class: "text-muted ms-2",
                        text: "Unit: " + item.item.unit
                    }) : "")
                )
                .appendTo(ul);
        };
    }

    // Inicializácia pre existujúce polia
    $('.item-description-input').each(function() {
        initAutocomplete($(this));
    });

    // Cocoon callback pre nové polia
    $(document).on('cocoon:after-insert', function(_e, insertedElement) {
        const $input = $('.item-description-input', insertedElement);
        if ($input.length) {
            initAutocomplete($input);
        }
    });
})